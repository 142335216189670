<template>
  <div class="container mt-4">
    <h1>{{ area.name }}</h1>
    <dl class="row mt-4 ms-4">
      <h4 class="mt-2">Základní údaje</h4>
      <dt class="col-sm-3 mt-3">Název</dt>
      <dd class="col-sm-9 mt-3">{{ area.name }}</dd>
      <dt class="col-sm-3">Město</dt>
      <dd class="col-sm-9">{{ area.city }}</dd>
      <dt class="col-sm-3">Index</dt>
      <dd class="col-sm-9">{{ area.index }}</dd>
      <dt class="col-sm-3">Veřejný</dt>
      <dd class="col-sm-9">{{ area.public?"Ano":"Ne"}}</dd>

      <dt class="col-sm-3 mt-3">Popis</dt>
      <dd class="col-sm-9 mt-3">
        <p>
          {{ area.description }}
        </p>
      </dd>
      <dt class="col-sm-3 mt-3">Nápověda</dt>
      <dd class="col-sm-9 mt-3">
        <p>
          {{ area.hint }}
        </p>
      </dd>

      <dt class="col-sm-3">Ikona</dt>
      <dd class="col-sm-7">{{ area?.icon?.url }}</dd>
      <dd class="col-sm-1">{{ area?.icon?.top }}</dd>
      <dd class="col-sm-1">{{ area?.icon?.left }}</dd>


      <dt class="col-sm-3">Zeměpisná šířka</dt>
      <dd class="col-sm-9">{{ area.location.lat }}</dd>
      <dt class="col-sm-3">Zeměpisná délka</dt>
      <dd class="col-sm-9">{{ area.location.lon }}</dd>
      <dt class="col-sm-3">Nadmořská výška</dt>
      <dd class="col-sm-9">{{ area.location.alt }} m n.m.</dd>

      <dd class="col-sm-3"></dd>
      <dd class="col-sm-9" v-if="!!area.location && Object.keys(area.location).length >= 2">
        <MapsCZ id="detail" :center-raw='area.location' :ar-border="area?.arBorder" :vr-border="area?.vrBorder"></MapsCZ>
      </dd>

      <dt class="row-sm-3">AR oblast</dt>

      <dd v-for="b in area?.arBorder" class="col-sm-3 border-start border-end">
          <dd class="col-sm-2"> {{b.lat}} </dd>
          <dd class="col-sm-2"> {{b.lon}} </dd>
          <dd class="col-sm-2"> {{b.alt}} </dd>
      </dd>

      <dt class="row-sm-3">VR oblast</dt>

      <dd v-for="b in area?.vrBorder" class="col-sm-3 border-start border-end">
      <dd class="col-sm-2"> {{b.lat}} </dd>
      <dd class="col-sm-2"> {{b.lon}} </dd>
      <dd class="col-sm-2"> {{b.alt}} </dd>
      </dd>
    </dl>

    <h4 class="mt-4">Struktury</h4>
      <div class="row">
        <div class="form-group">
          <ul>
            <li v-for="link in structures" :key="link"><a :href="'/detail/'+link.id">{{ link.name }}</a></li>
          </ul>
        </div>
      </div>

    <div class="mt-5 mb-3">
      <button @click="$router.go(-1)" type="button" class="btn btn-primary ms-4">Zpět</button>
      <button @click="$router.push({name: 'AreaEdit', params: { id: area.id }})" type="button"
              class="btn btn-success ms-2 me-2">
        Upravit
      </button>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import AreaService from '@/services/AreaService'
import MapsCZ from '@/components/MapsCZ'

export default {
  name: 'AreaDetail',
  components: { MapsCZ },
  data () {
    return {
      area: {
        location: {},
        icon: {},
        activationBorder: [{
        }],
        structures: [{}]
      }
    }
  },
  computed: {
    remappedLocation () {
      return {
        lat: this.area.location.lat,
        lng: this.area.location.lon
      }
    },
    remappedBorder () {
     var res = [];
     if(!!this.area.activationBorder) {
        for (var i = 0; i < this.area.activationBorder.length; i++)
            res.push({lat: this.area.activationBorder[i].lat, lng: this.area.activationBorder[i].lon});
     }
     return res;
    },
    structures () {
       var res = [];
       for (var i = 0; i < this.area.structures.length; i++)
          res.push({name: this.area.structures[i].name, id: this.area.structures[i].id});
//      const links = this.area.structures;
     /* if (links?.length === 1 && links[0] === '') {
        return null
      }*/
      return res;
    }
  },
  async mounted () {
    const route = useRoute()
    var temp = await AreaService.getById(route.params.id)
    this.area = temp.data
    console.log(this.area)
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="container mt-4">
    <h1>Přihlášení</h1>
    <div class="col col-sm-3">
      <label>Globální heslo</label>
      <input type="password" class="form-control" v-model="password" @keyup.enter="loginClick"/>
    </div>
    <button type="button" class="btn btn-primary mt-3"
            @click="loginClick">
      Přihlásit
    </button>
    <div class="alert alert-danger mt-3 col col-sm-3" role="alert" v-if="authFail">
      Špatné heslo!
    </div>
  </div>
</template>

<script>

import globalAuthService from '@/services/GlobalAuthService'
import GlobalAuthService from '@/services/GlobalAuthService'

export default {
  name: 'Login',
  data () {
    return {
      password: '',
      target: 'AreaList',
      authFail: false
    }
  },
  methods: {
    loginClick () {
      if (globalAuthService.authenticate(this.password)) {
        this.$router.push({ name: this.target })
      } else {
        this.password = ''
        this.authFail = true
      }
    }
  },
  async mounted () {
    if (GlobalAuthService.isAuthenticated()) {
      this.$router.push({ name: this.target })
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div :id="id" style="width:100%; height:600px;"></div>

</template>

<script>

export default {
  name: 'MapsCZ',
  props: {
    id: String,
    centerRaw: {},
    arBorder: Object,
    vrBorder: Object,
    dragFunction: { Type: Function }
  },
  data () {
    return {
      map: {},
      SMap: window.SMap,
      JAK: window.JAK,
      markerLayer: null,
      fillLayer: null
    }
  },
  computed: {
    center () {
      return this.SMap.Coords.fromWGS84(this.centerRaw.lon, this.centerRaw.lat)
    }
  },
  methods: {
    start (e) {
      const node = e.target.getContainer()
      node[this.SMap.LAYER_MARKER].style.cursor = 'grabbing'
    },
    stop (e) {
      const node = e.target.getContainer()
      node[this.SMap.LAYER_MARKER].style.cursor = 'grab'
      const coords = e.target.getCoords()

      coords.getAltitude().then((alt) => {
        const id = e.target.getId()
        const idx = id.replace('VR', '').replace('AR', '')
        this.dragFunction(coords.y, coords.x, Math.round(alt), id, idx)
      })
    },
    drawBorder (draggable, pointsArr, prefix, color) {
      // add point markers and area
      const points = pointsArr.map(x => this.SMap.Coords.fromWGS84(x.lon, x.lat))

      points.forEach((x, i) => {
        var container = this.JAK.mel('div', {}, draggable ? {
          cursor: 'grab'
        } : {})
        var popisek = this.JAK.mel('div', {}, {
          position: 'absolute',
          width: '1px',
          height: '1px',
          top: '15px',
          left: '7px',
          color: 'black',
          fontWeight: 'bold'
        })
        popisek.innerHTML = prefix + i
        container.appendChild(popisek)

        // + 1 protoze mapy 0 berou jako vychozi hodnotu, kterou zmeni na unikatni id 🤦
        var marker = new this.SMap.Marker(x, prefix + (i + 1), { url: container })

        if (draggable) {
          marker.decorate(this.SMap.Marker.Feature.Draggable)
        }

        this.markerLayer.addMarker(marker)
      })

      var polyline = new this.SMap.Geometry(this.SMap.GEOMETRY_POLYGON, null, points, {
        color: color,
        outlineColor: color
      })
      this.fillLayer.addGeometry(polyline)
    },
    rebuildMap () {
      // reset map
      this.markerLayer.removeAll()
      this.fillLayer.removeAll()

      const draggable = this.dragFunction !== undefined

      // add center marker
      const centerMark = new this.SMap.Marker(this.center, 'center')
      if (draggable) {
        centerMark.decorate(this.SMap.Marker.Feature.Draggable)
      }
      this.markerLayer.addMarker(centerMark)

      if (this.arBorder && (this.id === 'ar' || this.id === 'detail')) {
        this.drawBorder(draggable, this.arBorder, 'AR', '#f00')
      }
      if (this.vrBorder && (this.id === 'vr' || this.id === 'detail')) {
        this.drawBorder(draggable, this.vrBorder, 'VR', '#00f')
      }
    }
  },
  watch: {
    vrBorder: {
      deep: true,
      handler (n, o) {
        this.rebuildMap()
      }
    },
    arBorder: {
      deep: true,
      handler (n, o) {
        this.rebuildMap()
      }
    },
    center (o, n) {
      this.rebuildMap()
    }
  },
  mounted () {
    const center = this.center
    const map = this.map = new this.SMap(this.JAK.gel(this.id), center, 15)
    map.addDefaultLayer(this.SMap.DEF_BASE).enable()
    map.addDefaultControls()

    this.markerLayer = new this.SMap.Layer.Marker(center)
    this.fillLayer = new this.SMap.Layer.Geometry(center)

    this.map.addLayer(this.fillLayer).enable()
    this.map.addLayer(this.markerLayer).enable()

    if (this.dragFunction !== undefined) {
      const signals = this.map.getSignals()
      signals.addListener(window, 'marker-drag-stop', this.stop)
      signals.addListener(window, 'marker-drag-start', this.start)
    }

    this.rebuildMap()
  }
}

</script>

<style scoped>

</style>

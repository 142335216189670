<template>
  <div class="container mt-4">
    <button @click="$router.push({name: 'List', params: { }})" type="button"
            class="btn btn-success mt-3 mb-3 float-end">Struktury
    </button>
    <h1>Oblasti</h1>
    <div class="form-outline mt-5 mb-2">
      <div class="row">
        <div class="col">
          <input type="search" id="form1" class="form-control" placeholder="Hledat podle názvu" aria-label="Search"
                 v-model="searchName"/>
        </div>
        <div class="col">
          <input type="search" id="form2" class="form-control" placeholder="Hledat podle města" aria-label="Search"
                 v-model="searchCity"/>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col mt-4">
        <!--        <p class="text-secondary">-->
        <!--          Nepřihlášen-->
        <!--          <button type="button" class="btn btn-dark btn-sm ms-2">Přihlásit se</button>-->
        <!--        </p>-->
      </div>
      <div class="col">
        <button @click="$router.push({name: 'AreaAdd', params: { }})" type="button"
                class="btn btn-success mt-3 mb-3 float-end">Přidat novou
        </button>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th style="width: 3em">Index</th>
          <th style="width: 27em">Název</th>
          <th style="width: 15em">Město</th>
          <th style="width: 15em">Akce</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(a, idx) in filtered" :key="idx">
          <td>{{ a.index }}</td>
          <td>{{ a.name }}</td>
          <td>{{ a.city }}</td>
          <td>
            <button @click="$router.push({name: 'AreaDetail', params: { id: a.id }})" type="button"
                    class="btn btn-primary">
              Detail
            </button>
            <button @click="$router.push({name: 'AreaEdit', params: { id: a.id }})" type="button"
                    class="btn btn-success ms-2 me-2">Upravit
            </button>
            <button type="button" class="btn btn-danger"
                    @click="this.delete(a.id)">Odstranit
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import AreaService from '@/services/AreaService'

export default {
  name: 'AreaList',
  data () {
    return {
      searchName: '',
      searchCity: '',
      areas: []
    }
  },
  mounted () {
    this.reload()
  },
  computed: {
    filtered () {
      function compare (a, b) {
        if (a.index < b.index) {
          return -1
        }
        if (a.index > b.index) {
          return 1
        }
        return 0
      }
      this.areas.sort(compare)
      return this.areas.filter(area => {
        return area.name.toLowerCase().includes(this.searchName.toLowerCase()) &&
          area.city.toLowerCase().includes(this.searchCity.toLowerCase())
      })
    }
  },
  methods: {
    async delete (id) {
      if (!confirm('Opravdu chcete odstranit tuto oblast?')) {
        return
      }

      await AreaService.delete(id)
      await this.reload()
    },
    async reload () {
      const temp = await AreaService.getAll()
      this.areas = temp.data
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <AreaForm title="Přidat oblast" :area-input="area" @on-save="save"/>
</template>

<script>
import AreaService from '@/services/AreaService'
import AreaForm from '@/views/AreaForm'

export default {
  name: 'Detail',
  components: { AreaForm },
  data () {
    return {
      area: {
        public: true,
        city: 'Hradec Králové',
        name: 'Oblast',
        description: 'Popis',
        location: {
          lat: 50.21168814233986,
          lon: 15.829054423446651,
          alt: 200
        },
        vrBorder: [],
        arBorder: []
      }
    }
  },
  methods: {
    async save () {
      await AreaService.post(this.area)
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
</style>

<template>
  <div class="container mt-4">
    <button @click="$router.push({name: 'AreaList', params: { }})" type="button"
                class="btn btn-success mt-3 mb-3 float-end">Oblasti
        </button>
    <h1>Struktury</h1>
    <div class="form-outline mt-5 mb-2">
      <div class="row">
        <div class="col">
          <input type="search" id="form1" class="form-control" placeholder="Hledat podle názvu" aria-label="Search"
                 v-model="searchName"/>
        </div>
        <div class="col">
          <input type="search" id="form2" class="form-control" placeholder="Hledat podle města" aria-label="Search"
                 v-model="searchCity"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col mt-4">
<!--        <p class="text-secondary">-->
<!--          Nepřihlášen-->
<!--          <button type="button" class="btn btn-dark btn-sm ms-2">Přihlásit se</button>-->
<!--        </p>-->
      </div>
      <div class="col">
        <button @click="$router.push({name: 'Add', params: { }})" type="button"
                class="btn btn-success mt-3 mb-3 float-end">Přidat novou
        </button>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th style="width: 30em;">Název</th>
          <th style="width: 15em;">Město</th>
          <th style="width: 15em;">Akce</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(s, idx) in filtered" :key="idx">
          <td>{{ s.name }}</td>
          <td>{{ s.city }}</td>
          <td>
            <button @click="$router.push({name: 'Detail', params: { id: s.id }})" type="button" class="btn btn-primary">
              Detail
            </button>
            <button @click="$router.push({name: 'Edit', params: { id: s.id }})" type="button"
                    class="btn btn-success ms-2 me-2">Upravit
            </button>
            <button type="button" class="btn btn-danger"
                    @click="this.delete(s.id)">Odstranit
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import StructureService from '@/services/StructureService'

export default {
  name: 'List',
  data () {
    return {
      searchName: '',
      searchCity: '',
      structures: []
    }
  },
  mounted () {
    this.reload()
  },
  computed: {
    filtered () {
      return this.structures.filter(structure => {
        return structure.name.toLowerCase().includes(this.searchName.toLowerCase()) &&
          structure.city.toLowerCase().includes(this.searchCity.toLowerCase())
      })
    }
  },
  methods: {
    async delete (id) {
      if (!confirm('Opravdu chcete odstranit tuto strukturu?')) {
        return
      }

      await StructureService.delete(id)
      await this.reload()
    },
    async reload () {
      const temp = await StructureService.getAll()
      this.structures = temp.data
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <Menu v-if="this.$route.name !== 'Login'" />
  <div class="pt-5">
  <router-view/>
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
export default {
  name: 'App',
  components: {
    Menu
  }
}
</script>

import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'https://vmck.sic.cz:3443/',
  headers: {
    'Content-type': 'application/json',
    Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIxMDYwZmY1NC00ZWI1LTQ1MGYtYjQwZi0wZmJkYThkYzJlMTYiLCJpYXQiOjE2Mzg5NTY3ODN9.9yL8H5ELPVLzP8J7hNVsk_JzIvbdDYxsua3DaQ54L3E'
  }
})

export default apiClient

<template>
  <StructureForm title="Upravit strukturu" v-bind:structure-input="structure"
                 @on-save="save" ref="form" :show-model-management="true" :save-success="saveSuccess" :save-fail="saveFail"/>

</template>

<script>
import StructureService from '@/services/StructureService'
import StructureForm from '@/views/StructureForm'
import { useRoute } from 'vue-router'

export default {
  name: 'Edit',
  components: { StructureForm },
  data () {
    return {
      structure: {
        location: {}
      },
      saveSuccess: false,
      saveFail: false
    }
  },
  async mounted () {
    const route = useRoute()
    var temp = await StructureService.getById(route.params.id)
    this.structure = temp.data
    this.$refs.form.updateStructure(temp.data)
  },
  methods: {
    save () {
      StructureService.put(this.structure).then(() => {
        this.saveSuccess = true
        console.log('test')
        setTimeout(() => {
          this.saveSuccess = false
        }, 3000)
      }).catch(() => {
        this.saveFail = true
        setTimeout(() => {
          this.saveFail = false
        }, 3000)
      })
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="container mt-4">
    <h1>{{ title }}</h1>

    <form>
      <div class="row">
        <div class="col">
          <h4 class="mt-4">Základní údaje</h4>
          <div class="form-group mt-3">
            <label>Název</label>
            <input type="text" class="form-control" v-model="structure.name"/>
          </div>

          <div class="form-group mt-3">
            <label>Popis</label>
            <textarea
              class="form-control"
              rows="5"
              v-model="structure.description"
            ></textarea>
          </div>

          <div class="row mt-3">
            <div class="col">
              <label>Typ</label>
              <div class="d-flex">
                <select class="form-select">
                  <option selected>Město</option>
                  <option value="1">Budova</option>
                  <option value="2">Místnost</option>
                </select>

                <div class="d-flex align-items-center ms-2">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                  <label class="form-check-label user-select-none ms-1" for="flexCheckDefault">
                    Miniatura
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h4 class="mt-4">Pozice</h4>
      <div class="row">
        <div class="col col-sm-3">
          <label>Zeměpisná šířka</label>
          <input
            type="number"
            class="form-control"
            v-model="structure.location.lat"
          />
        </div>

        <div class="col col-sm-3">
          <label>Zeměpisná délka</label>
          <input
            type="number"
            class="form-control"
            v-model="structure.location.lon"
          />
        </div>

        <div class="col col-sm-3">
          <label>Nadmořská výška</label>
          <input
            type="number"
            class="form-control"
            v-model="structure.location.alt"
          />
        </div>

        <div class="col col-sm-3">
          <label>Město</label>
          <input
            type="text"
            class="form-control"
            v-model="structure.city"
          />
        </div>
      </div>

      <div class="row mt-5" v-if="!!structure.location && Object.keys(structure.location).length >= 2">
        <MapsCZ id="ar" :center-raw='structure.location' :drag-function="updatePosition"></MapsCZ>
        <div
          class="
            col
            flex-grow-1
            justify-content-center
            align-items-center
            d-flex
            bg-light
            rounded
          "
        >
          <div>Žádná fotka. <a>Nahrát...</a></div>
        </div>
      </div>

      <!--      <h4 class="mt-4">Parametry</h4>-->
      <!--      <div class="row">-->
      <!--        <div class="form-group mt-3 col col-sm-2">-->
      <!--          <label>Postaveno</label>-->
      <!--          <input type="number" class="form-control" v-model="structure.properties.built"/>-->
      <!--        </div>-->
      <!--        <div class="form-group mt-3 col col-sm-2">-->
      <!--          <label>Zničeno</label>-->
      <!--          <input type="number" class="form-control" v-model="structure.properties.destroyed"/>-->
      <!--        </div>-->
      <!--      </div>-->

      <h4 class="mt-4">Odkazy</h4>
      <div class="row">
        <div class="form-group">
          <textarea class="form-control" rows="8" v-model="structure.html_link"></textarea>
        </div>
      </div>

      <div class="mt-5 mb-3">
        <button
          @click="$router.go(-1)"
          type="button"
          class="btn btn-primary">
          Zpět
        </button>

        <button type="button" class="btn btn-primary ms-2"
                v-if="showModelManagement"
                @click="$router.push({name: 'Edit3dObject', params: { id: structure.id }})">
          Spravovat modely
        </button>

        <button
          type="button"
          class="btn btn-success ms-2 me-2"
          @click="$emit('on-save', structure)"
        >
          Uložit
        </button>

        <div v-if="saveSuccess" class="alert alert-success d-inline" role="alert">Úspěšně uloženo!</div>
        <div v-if="saveFail" class="alert alert-danger d-inline" role="alert">Uložení selhalo!</div>
      </div>
    </form>
  </div>
</template>

<script>
import MapsCZ from '@/components/MapsCZ'

export default {
  name: 'StructureForm',
  components: { MapsCZ },
  props: {
    title: String,
    structureInput: Object,
    showModelManagement: Boolean,
    saveSuccess: Boolean,
    saveFail: Boolean
  },
  data () {
    return {
      structure: this.structureInput,
      times: 0
    }
  },
  computed: {
    remappedLocation () {
      return {
        lat: this.structure?.location?.lat,
        lng: this.structure?.location?.lon
      }
    }
  },
  methods: {
    updatePosition: function (lat, lon, alt) {
      this.structure.location.lat = lat
      this.structure.location.lon = lon
      this.structure.location.alt = alt
    },
    updateStructure (val) {
      this.structure = val
    }
  }
}
</script>

<style scoped>

</style>

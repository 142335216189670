<template>
  <StructureForm title="Přidat strukturu" :structure-input="structure" @on-save="save"/>
</template>

<script>
import StructureService from '@/services/StructureService'
import StructureForm from '@/views/StructureForm'

export default {
  name: 'Detail',
  components: { StructureForm },
  data () {
    return {
      structure: {
        public: true,
        city: 'Hradec Králové',
        name: '-',
        description: 'Popis',
        location: {
          lat: 50.21168814233986,
          lon: 15.829054423446651,
          alt: 200
        },
        icon: {
          top: 0.5,
          left: 0.5,
          url: 'https://www.kralovskavennamesta.cz/img/favicon.ico'
        },
        properties: {
          built: 1620,
          destroyed: 1648
        }
      }
    }
  },
  methods: {
    async save () {
      await StructureService.post(this.structure)
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
</style>

import { createRouter, createWebHistory } from 'vue-router'

import List from '../views/List.vue'
import Detail from '../views/Detail.vue'
import Add from '../views/Add.vue'
import Edit from '../views/Edit.vue'
import Edit3dObject from '@/views/Edit3dObject'

import AreaList from '../views/AreaList.vue'
import AreaDetail from '../views/AreaDetail.vue'
import AreaAdd from '../views/AreaAdd.vue'
import AreaEdit from '../views/AreaEdit.vue'
import GlobalAuthService from '@/services/GlobalAuthService'
import Login from '@/views/Login'

const routes = [
  {
    path: '/',
    name: 'AreaList',
    component: AreaList
  },
  {
    path: '/structure',
    name: 'List',
    component: List
  },
  {
    path: '/detail/:id',
    name: 'Detail',
    component: Detail
  },
  {
    path: '/add',
    name: 'Add',
    component: Add
  },
  {
    path: '/edit/:id',
    name: 'Edit',
    component: Edit
  },
  {
    path: '/edit3dobject/:id',
    name: 'Edit3dObject',
    component: Edit3dObject
  },
  {
    path: '/areas/detail/:id',
    name: 'AreaDetail',
    component: AreaDetail
  },
  {
    path: '/areas/add',
    name: 'AreaAdd',
    component: AreaAdd
  },
  {
    path: '/areas/edit/:id',
    name: 'AreaEdit',
    component: AreaEdit
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  if (to.name !== 'Login' && !GlobalAuthService.isAuthenticated()) {
    router.push({ name: 'Login' })
  }
})

export default router

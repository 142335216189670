import api from '@/ApiClient'

class AreaService {
  getAll () {
    return api.get('/areas')
  }

  getById (id) {
    return api.get(`/areas/${id}`)
  }

  async post (data) {
    await api.post('/areas', this.prepareData(data))
  }

  async put (data) {
    await api.put(`/areas/${data.id}`, this.prepareData(data))
  }

  prepareData (data) {
    let clone = JSON.parse(JSON.stringify(data))
    clone.location = JSON.stringify(clone.location)
    clone = {
      name: clone.name,
      city: clone.city,
      index: clone.index+"",
      description: clone.description,
      hint: clone.hint,
      location: clone.location,
      arBorder: JSON.stringify(clone.arBorder),
      vrBorder: JSON.stringify(clone.vrBorder)
    }
    console.log(clone)
    return clone
  }

  async delete (id) {
    await api.delete(`/areas/${id}`)
  }
}

export default new AreaService()

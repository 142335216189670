<template>
  <div class="container mt-4">
    <h1>{{ structure.name }}</h1>
    <dl class="row mt-4 ms-4">
      <h4 class="mt-2">Základní údaje</h4>
      <dt class="col-sm-3 mt-3">Název</dt>
      <dd class="col-sm-9 mt-3">{{ structure.name }}</dd>
      <dt class="col-sm-3 mt-3">Popis</dt>
      <dd class="col-sm-9 mt-3">
        <p>
          {{ structure.description }}
        </p>
      </dd>
      <dt class="col-sm-3">Zeměpisná šířka</dt>
      <dd class="col-sm-9">{{ structure.location.lat }}</dd>
      <dt class="col-sm-3">Zeměpisná délka</dt>
      <dd class="col-sm-9">{{ structure.location.lon }}</dd>
      <dt class="col-sm-3">Nadmořská výška</dt>
      <dd class="col-sm-9">{{ structure.location.alt }} m n.m.</dd>
      <dt class="col-sm-3">Město</dt>
      <dd class="col-sm-9">{{ structure.city }}</dd>
      <dd class="col-sm-3"></dd>
      <dd class="col-sm-9" v-if="!!structure.location && Object.keys(structure.location).length >= 2">
        <MapsCZ id="ar" :center-raw='structure.location'></MapsCZ>
      </dd>
      <h4 class="mt-5">Parametry</h4>
      <dt class="col-sm-3 mt-2">Postaveno</dt>
      <dd class="col-sm-9 mt-2">{{ structure?.properties?.[0]?.built }}</dd>
      <dt class="col-sm-3">Zničeno</dt>
      <dd class="col-sm-9">{{ structure?.properties?.[0]?.destroyed }}</dd>

      <h4 class="mt-4">Odkazy</h4>
      <div class="row">
        <div class="form-group">
          <ul>
            <li v-for="link in links" :key="link"><a :href="link">{{ link }}</a></li>
          </ul>
        </div>
      </div>
    </dl>

    <div class="mt-5 mb-3">
      <button @click="$router.go(-1)" type="button" class="btn btn-primary ms-4">Zpět</button>

      <button @click="$router.push({name: 'Edit', params: { id: structure.id }})" type="button"
              class="btn btn-success ms-2 me-2">
        Upravit
      </button>
      <button @click="$router.push({name: 'Edit3dObject', params: { id: structure.id }})" type="button"
             class="btn btn-success ms-primary ms-2">
        Spravovat modely
      </button>

    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import StructureService from '@/services/StructureService'
import MapsCZ from '@/components/MapsCZ'

export default {
  name: 'Detail',
  data () {
    return {
      structure: {
        location: {},
        properties: [{
          built: 0,
          destroyed: 0
        }
        ]
      }
    }
  },
  computed: {
    remappedLocation () {
      return {
        lat: this.structure.location.lat,
        lng: this.structure.location.lon
      }
    },
    links () {
      const links = this.structure.html_link?.split('\n')
      if (links?.length === 1 && links[0] === '') {
        return null
      }
      return links
    }
  },
  components: { MapsCZ },
  async mounted () {
    const route = useRoute()
    const temp = await StructureService.getById(route.params.id)
    this.structure = temp.data
  }
}
</script>

<style scoped>

</style>

import { authExpireDays, globalPassword } from '@/config'

class GlobalAuthService {
  constructor () {
    const auth = new Date(localStorage.getItem('sessionExpire'))
    this.auth = false
    if (auth !== undefined && auth.getTime() > new Date().getTime()) {
      this.auth = true
    }
  }

  isAuthenticated () {
    return this.auth
  }

  authenticate (password) {
    if (password === globalPassword) {
      const expire = new Date()
      expire.setDate(expire.getDate() + authExpireDays)
      localStorage.setItem('sessionExpire', expire.toJSON())
      this.auth = true
    }
    return this.auth
  }
}

export default new GlobalAuthService()

import api from '@/ApiClient'

class StructureService {
  getAll () {
    return api.get('/structures')
  }

  getById (id) {
    return api.get(`/structures/${id}`)
  }

  async post (data) {
    await api.post('/structures', this.prepareData(data))
  }

  async put (data) {
    await api.put(`/structures/${data.id}`, this.prepareData(data))
  }

  prepareData (data) {
    let clone = JSON.parse(JSON.stringify(data))
    clone.location = JSON.stringify(clone.location)
    clone = {
      name: clone.name,
      location: clone.location,
      description: clone.description,
      city: clone.city,
      html_link: clone.html_link
    }
    return clone
  }

  async delete (id) {
    await api.delete(`/structures/${id}`)
  }

  async get3dObjects (id) {
    return await api.get('/3Dobjects/', { params: { structureId: id } })
  }
}

export default new StructureService()

<template>
  <div class="container mt-4">
    <h1>{{ title }}</h1>

    <form>
      <div class="row">
        <div class="col">
          <h4 class="mt-4">Základní údaje</h4>
          <div class="form-group mt-3">
            <label>Název*</label>
            <input type="text" class="form-control" v-model="area.name" required>
          </div>
          <!--          <p v-if="errors" class="error">-->
          <!--            <span v-if="!area.name.required">this field is required.</span>-->
          <!--          </p>-->
          <div class="form-group mt-3">
            <label>Město</label>
            <input type="text" class="form-control" v-model="area.city" required/>
          </div>
          <div class="form-group mt-3">
            <label>Index</label>
            <input type="number" class="form-control" v-model="area.index" required/>
          </div>
          <div class="form-group mt-3">
            <label>Popis</label>
            <textarea
              class="form-control"
              rows="5"
              v-model="area.description" required
            ></textarea>
          </div>
          <div class="form-group mt-3">
            <label>Nápověda</label>
            <textarea
              class="form-control"
              rows="5"
              v-model="area.hint" required
            ></textarea>
          </div>
        </div>
      </div>

      <h4 class="mt-4">Pozice</h4>
      <div class="row">
        <div class="col col-sm-3">
          <label>Zeměpisná šířka</label>
          <input
            type="number"
            class="form-control"
            v-model="area.location.lat"
          />
        </div>

        <div class="col col-sm-3">
          <label>Zeměpisná délka</label>
          <input
            type="number"
            class="form-control"
            v-model="area.location.lon"
          />
        </div>

        <div class="col col-sm-3">
          <label>Nadmořská výška</label>
          <input
            type="number"
            class="form-control"
            v-model="area.location.alt"
          />
        </div>

      </div>

      <div class="row mt-5" v-if="!!area.location && Object.keys(area.location).length >= 2">
        <div class="col">
          <MapsCZ id="ar" :center-raw='area?.location' :ar-border="area?.arBorder" :vr-border="area?.vrBorder" :drag-function='updatePosition'></MapsCZ>
        </div>
        <div class="col">
          <MapsCZ id="vr" :center-raw='area?.location' :ar-border="area?.arBorder" :vr-border="area?.vrBorder" :drag-function='updatePosition'></MapsCZ>
        </div>
      </div>

      <div class="row">
        <div class="col">
      <h4 class="mt-4">AR oblast</h4>
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th>Zeměpisná šířka</th>
            <th>Zeměpisná délka</th>
            <th>Nadmořská výška</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="point in area?.arBorder">
            <td>
              <input
                type="number"
                class="form-control"
                v-model="point.lat"
              />
            </td>
            <td>
              <input
                type="number"
                class="form-control"
                v-model="point.lon"
              />
            </td>
            <td>
              <input
                type="number"
                class="form-control"
                v-model="point.alt"
              />
            </td>
            <td>
              <button
                type="button"
                class="btn btn-outline-danger"
                @click="deleteBorderPoint(point, 'ar')">
                ❌
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <div style="text-align: center">
          <button
            type="button"
            class="btn btn-primary ms-2"
            @click="addActivationPoint('ar')">
            Přidat bod
          </button>
        </div>
      </div>
        </div>

      <!-- TODO: Maybe strip to dedicated component to avoid duplicating code... -->
        <div class="col">
      <h4 class="mt-4">VR oblast</h4>
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th>Zeměpisná šířka</th>
            <th>Zeměpisná délka</th>
            <th>Nadmořská výška</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="point in area?.vrBorder">
            <td>
              <input
                type="number"
                class="form-control"
                v-model="point.lat"
              />
            </td>
            <td>
              <input
                type="number"
                class="form-control"
                v-model="point.lon"
              />
            </td>
            <td>
              <input
                type="number"
                class="form-control"
                v-model="point.alt"
              />
            </td>
            <td>
              <button
                type="button"
                class="btn btn-outline-danger"
                @click="deleteBorderPoint(point, 'vr')">
                ❌
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <div style="text-align: center">
          <button
            type="button"
            class="btn btn-primary ms-2"
            @click="addActivationPoint('vr')">
            Přidat bod
          </button>
        </div>
      </div>
        </div>
      </div>
      <h4 class="mt-4">Odkazy</h4>
      <div class="row">
        <div class="form-group">
          <textarea class="form-control" rows="8" v-model="area.html_link"></textarea>
        </div>
      </div>

      <div class="mt-5 mb-3">
        <button
          @click="$router.go(-1)"
          type="button"
          class="btn btn-primary">
          Zpět
        </button>

        <button type="button" class="btn btn-primary ms-2"
                v-if="showModelManagement"
                @click="$router.push({name: 'Edit3dObject', params: { id: area.id }})">
          Spravovat modely
        </button>

        <button
          type="button"
          class="btn btn-success ms-2 me-2"
          @click="$emit('on-save', area)"
        >
          Uložit
        </button>

        <div v-if="saveSuccess" class="alert alert-success d-inline" role="alert">Úspěšně uloženo!</div>
        <div v-if="saveFail" class="alert alert-danger d-inline" role="alert">Uložení selhalo!</div>
      </div>
    </form>
  </div>
</template>

<script>
import MapsCZ from '@/components/MapsCZ'

export default {
  name: 'AreaForm',
  components: { MapsCZ },
  props: {
    title: String,
    areaInput: Object,
    showModelManagement: Boolean,
    saveSuccess: Boolean,
    saveFail: Boolean
  },
  data () {
    return {
      area: this.areaInput,
      times: 0,
      isActivationInputValid: true
    }
  },
  computed: {
    remappedLocation () {
      return {
        lat: this.area?.location?.lat,
        lng: this.area?.location?.lon
      }
    }
  },
  methods: {
    updatePosition: function (lat, lon, alt, id, idx) {
      console.log(id)
      if (id === 'center') {
        this.area.location.lat = lat
        this.area.location.lon = lon
        this.area.location.alt = alt
      } else {
        idx -= 1
        if (id.startsWith('VR')) {
          this.area.vrBorder[idx].lat = lat
          this.area.vrBorder[idx].lon = lon
          this.area.vrBorder[idx].alt = alt
        } else if (id.startsWith('AR')) {
          this.area.arBorder[idx].lat = lat
          this.area.arBorder[idx].lon = lon
          this.area.arBorder[idx].alt = alt
        }
      }
    },
    updateArea (val) {
      this.area = val
    },
    deleteBorderPoint (arg, type) {
      var border = this.getBorderArr(type)
      // delete ALL occurrences
      // const filtered = this.area.activationBorder.remove(point => {
      //   return point.alt !== arg.alt ||
      //          point.lat !== arg.lat ||
      //          point.lon !== arg.lon
      // })
      border.splice(border.indexOf(arg), 1)
      this.resetTmpCoords()
    },
    addActivationPoint (type) {
      var border = this.getBorderArr(type)
      if (!border) {
        border = []
      }

      const count = border.length

      var point = {}
      if (count === 0) {
        point = {
          lat: this.area.location.lat - 0.001,
          lon: this.area.location.lon,
          alt: this.area.location.alt
        }
      } else if (count === 1) {
        point = {
          lat: border[0].lat - 0.001,
          lon: border[0].lon,
          alt: border[0].alt
        }
      } else {
        point = {
          lat: (border[count - 1].lat + border[0].lat) / 2,
          lon: (border[count - 1].lon + border[0].lon) / 2,
          alt: (border[count - 1].alt + border[0].alt) / 2
        }
      }

      border.push(point)
    },
    getBorderArr (type) {
      if (type === 'vr') {
        return this.area.vrBorder
      } else {
        return this.area.arBorder
      }
    },
    resetTmpCoords () {
      this.tmpCoords = {
        lat: 0,
        lon: 0,
        alt: 0
      }
    },
    validateActivationInputs () {
      console.log(this.tmpCoords)
      this.isActivationInputValid = this.validateAltitudeInput() && this.validateLongitudeInput() && this.validateLatitudeInput()
      return this.isActivationInputValid
    },
    validateLatitudeInput () {
      if (this.tmpCoords.lat === undefined || this.tmpCoords.lat === null || this.tmpCoords.lat === '') return false
      if (this.tmpCoords.lat < -180 || this.tmpCoords.lat > 180) return false
      return true
    },
    validateLongitudeInput () {
      if (this.tmpCoords.lon === undefined || this.tmpCoords.lon === null || this.tmpCoords.lon === '') return false
      if (this.tmpCoords.lon < -90 || this.tmpCoords.lon > 90) return false
      return true
    },
    validateAltitudeInput () {
      if (this.tmpCoords.alt === undefined || this.tmpCoords.alt === null || this.tmpCoords.alt === '') return false
      return true
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <AreaForm title="Upravit Oblast" v-bind:area-input="area"
                 @on-save="save" ref="form" :show-model-management="true" :save-success="saveSuccess" :save-fail="saveFail"/>

</template>

<script>
import AreaService from '@/services/AreaService'
import AreaForm from '@/views/AreaForm'
import { useRoute } from 'vue-router'

export default {
  name: 'AreaEdit',
  components: { AreaForm },
  data () {
    return {
      area: {
        location: {}
      },
      name: null,
      saveSuccess: false,
      saveFail: false
    }
  },
  async mounted () {
    const route = useRoute()
    var temp = await AreaService.getById(route.params.id)
    this.area = temp.data
    this.$refs.form.updateArea(temp.data)
  },
  methods: {
    checkForm: function (e) {
      if (this.name && this.city) {
        return true;
      }

      this.errors = [];

      if (!this.name) {
        this.errors.push('Name required.');
      }
      if (!this.age) {
        this.errors.push('Age required.');
      }

      e.preventDefault();
    },
    save () {
      AreaService.put(this.area).then(() => {
        this.saveSuccess = true
        console.log('test')
        setTimeout(() => {
          this.saveSuccess = false
        }, 3000)
      }).catch(() => {
        this.saveFail = true
        setTimeout(() => {
          this.saveFail = false
        }, 3000)
      })
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="container mt-4">
    <h1>3D objekty struktury '{{ structure.name }}'</h1>

    <div class="mt-3 mb-3 d-flex">
      <input type="search" class="form-control w-auto me-2"
             placeholder="Název nového objektu..." aria-label="Search" v-model="newName"/>
      <label class="btn btn-success" :class="[newName ? '' : 'disabled']" for="objectCreate">Vybrat soubor a
        nahrát</label>
      <input type="file" id="objectCreate" class="visually-hidden"
             @change="createNewObject"/>
    </div>

    <div class="accordion">

      <div class="accordion-item" v-for="(object, i) in objects" :key="i">
        <h2 class="accordion-header" :id="i">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  :data-bs-target="`#panel-pevnost-collapse-${i}`" aria-expanded="false"
                  :aria-controls="`panel-pevnost-collapse-${i}`">
            {{ object.name }}
            <span class="badge bg-primary ms-2" v-if="object.id === structure.defaultObject.id">Výchozí</span>
            <span class="badge bg-warning ms-2 text-black"
                  v-if="JSON.stringify(object) !== JSON.stringify(origObjects[i])">Změněno</span>

            <div class="flex-fill"/>

            <button type="button" v-if="object.id !== structure.defaultObject.id"
                    class="btn btn-primary btn-sm me-1"
                    @click.stop="download3dObject(object)">
              Nastavit jako výchozí
            </button>

            <button type="button"
                    class="btn btn-primary btn-sm me-4"
                    @click.stop="download3dObject(object)">
              Stáhnout
            </button>
          </button>
        </h2>
        <div :id="`panel-pevnost-collapse-${i}`" class="accordion-collapse collapse" :aria-labelledby="i">
          <div class="accordion-body">
            <form class="mt-3 mb-3">
              <h6 class="mt-2">Translace</h6>
              <div class="form-group mt-1 row">
                <div class="col-6 col-sm-2">
                  <label>X</label>
                  <input type="number" class="form-control"
                         :class="[object.transformation.translation[0] !== origObjects[i].transformation.translation[0] ? 'bg-warning' : '']"
                         v-model="object.transformation.translation[0]">
                </div>
                <div class="col-6 col-sm-2">
                  <label>Y</label>
                  <input type="number" class="form-control"
                         :class="[object.transformation.translation[1] !== origObjects[i].transformation.translation[1] ? 'bg-warning' : '']"
                         v-model="object.transformation.translation[1]">
                </div>
                <div class="col-6 col-sm-2">
                  <label>Z</label>
                  <input type="number" class="form-control"
                         :class="[object.transformation.translation[2] !== origObjects[i].transformation.translation[2] ? 'bg-warning' : '']"
                         v-model="object.transformation.translation[2]">
                </div>
              </div>
              <h6 class="mt-2">Rotace</h6>
              <div class="form-group mt-1 row">
                <div class="col-6 col-sm-2">
                  <label>X</label>
                  <input type="number" class="form-control"
                         :class="[object.transformation.rotation[0] !== origObjects[i].transformation.rotation[0] ? 'bg-warning' : '']"
                         v-model="object.transformation.rotation[0]">
                </div>
                <div class="col-6 col-sm-2">
                  <label>Y</label>
                  <input type="number" class="form-control"
                         :class="[object.transformation.rotation[1] !== origObjects[i].transformation.rotation[1] ? 'bg-warning' : '']"
                         v-model="object.transformation.rotation[1]">
                </div>
                <div class="col-6 col-sm-2">
                  <label>Z</label>
                  <input type="number" class="form-control"
                         :class="[object.transformation.rotation[2] !== origObjects[i].transformation.rotation[2] ? 'bg-warning' : '']"
                         v-model="object.transformation.rotation[2]">
                </div>
                <div class="col-6 col-sm-2">
                  <label>W</label>
                  <input type="number" class="form-control"
                         :class="[object.transformation.rotation[3] !== origObjects[i].transformation.rotation[3] ? 'bg-warning' : '']"
                         v-model="object.transformation.rotation[3]">
                </div>
              </div>
              <h6 class="mt-2">Scale</h6>
              <div class="form-group mt-1 row">
                <div class="col-6 col-sm-2">
                  <label>X</label>
                  <input type="number" class="form-control" v-model="object.transformation.scale[0]"
                         :class="[object.transformation.scale[0] !== origObjects[i].transformation.scale[0] ? 'bg-warning' : '']">
                </div>
                <div class="col-6 col-sm-2">
                  <label>Y</label>
                  <input type="number" class="form-control" v-model="object.transformation.scale[1]"
                         :class="[object.transformation.scale[1] !== origObjects[i].transformation.scale[1] ? 'bg-warning' : '']">
                </div>
                <div class="col-6 col-sm-2">
                  <label>Z</label>
                  <input type="number" class="form-control" v-model="object.transformation.scale[2]"
                         :class="[object.transformation.scale[2] !== origObjects[i].transformation.scale[2] ? 'bg-warning' : '']">
                </div>
              </div>
              <button type="button" class="btn btn-primary mt-3 me-2" @click="resetToZero(object)">Vynulovat</button>
              <button type="button" class="btn btn-primary mt-3" @click="resetToOriginal(object, i)">Vrátit změny
              </button>
            </form>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panel-bunkr-var1-assets">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        :data-bs-target="`#panel-pevnost-assets-collapse-${i}`" aria-expanded="false"
                        :aria-controls="`panel-pevnost-assets-collapse-${i}`">
                  Assety
                </button>
              </h2>
              <div :id="`panel-pevnost-assets-collapse-${i}`" class="accordion-collapse collapse" :aria-labelledby="i">
                <div class="accordion-body">
                  <div class="table-responsive">
                    <label class="btn btn-success" :for="`assetUpload-${i}`">
                      Nahrát nový
                    </label>
                    <input type="file" :id="`assetUpload-${i}`" class="visually-hidden"
                           @change="event => uploadAsset(event, `/3Dobjects/${object.id}/assets`)"/>
                    <table class="table">
                      <thead>
                      <tr>
                        <th>Název</th>
                        <th style="width: 400px;">Akce</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(asset, j) in object.assets" :key="j">
                        <td>{{ asset.filename }}</td>
                        <td>
                          <button type="button"
                                  class="btn btn-danger"
                                  @click="this.delete(`/assets/${asset.id}`)">
                            Odstranit
                          </button>

                          <button type="button"
                                  class="btn btn-primary ms-2"
                                  @click="downloadFile(asset, 'assets')"
                          >
                            Stáhnout
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panel-bunkr-var1-tex">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        :data-bs-target="`#panel-pevnost-tex-collapse-${i}`" aria-expanded="false"
                        :aria-controls="`panel-pevnost-tex-collapse-${i}`">
                  Textury
                </button>
              </h2>
              <div :id="`panel-pevnost-tex-collapse-${i}`" class="accordion-collapse collapse" :aria-labelledby="i">
                <div class="accordion-body">
                  <div class="d-flex">
                    <select class="form-select w-auto me-2" ref="textureTypes">
                      <option value="basecolor" selected>basecolor</option>
                      <option value="specular">specular</option>
                      <option value="metallic">metallic</option>
                      <option value="roughness">roughness</option>
                      <option value="normal">normal</option>
                      <option value="height">height</option>
                      <option value="ao">ao</option>
                    </select>

                    <label class="btn btn-success" :for="`textureUpload-${i}`">
                      Nahrát novou
                    </label>
                    <input type="file" :id="`textureUpload-${i}`" class="visually-hidden"
                           @change="event => uploadTexture(event, `/3Dobjects/${object.id}/textures`,
                                    $refs.textureTypes[i].value)"/>
                  </div>

                  <table class="table">
                    <thead>
                    <tr>
                      <th>Název</th>
                      <th style="width: 400px;">Akce</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(texture, j) in object.textures" :key="j">
                      <td>{{ texture.filename }}</td>
                      <td>
                        <button type="button"
                                class="btn btn-danger"
                                @click="this.delete(`/textures/${texture.id}`)">
                          Odstranit
                        </button>

                        <button type="button"
                                class="btn btn-primary ms-2"
                                @click="downloadFile(texture, 'textures')"
                        >
                          Stáhnout
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 mb-3">
      <button @click="$router.go(-1)" type="button" class="btn btn-danger me-2">Zpět</button>
      <button @click="save" type="button" class="btn btn-success">Uložit</button>

      <span class="badge bg-warning ms-2 text-black"
            v-if="JSON.stringify(objects) !== JSON.stringify(origObjects)">Změněno</span>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import StructureService from '@/services/StructureService'
import api from '@/ApiClient'

export default {
  name: 'Edit3dObject',
  data () {
    return {
      objects: {},
      origObjects: {},
      api: api,
      newName: '',
      id: useRoute().params.id,
      structure: {}
    }
  },
  async mounted () {
    await this.reload()
  },
  methods: {
    resetToZero (obj) {
      obj.transformation = {
        ...(obj.transformation),
        translation: [0, 0, 0],
        rotation: [0, 0, 0, 1],
        scale: [1, 1, 1]
      }
    },
    resetToOriginal (obj, index) {
      obj.transformation = JSON.parse(JSON.stringify(this.origObjects[index].transformation))
    },
    async download3dObject (object) {
      console.log(object)
      const details = (await api.get(`/3Dobjects/${object.id}`)).data
      this.downloadFile({
        id: details.modelId,
        filename: 'test.model'
      }, '/models')
    },
    downloadFile (file, url) {
      api.get(`${url}/${file.id}`, { responseType: 'blob' }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', file.filename)
        document.body.appendChild(link)
        link.click()
      })
    },
    getFormat (filename) {
      return filename.split('.').at(-1).toUpperCase()
    },
    async uploadAsset (event, url) {
      const formData = new FormData()
      formData.append('format', this.getFormat(event.target.files[0].name))
      formData.append('asset', event.target.files[0])
      await this.postSafe(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      await this.reload()
    },
    async uploadTexture (event, url, textureType) {
      const formData = new FormData()
      formData.append('textureType', textureType)
      formData.append('texture', event.target.files[0])
      await this.postSafe(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      await this.reload()
    },
    async createNewObject (event) {
      const formData = new FormData()
      formData.append('name', this.newName)
      formData.append('model', event.target.files[0])
      formData.append('format', this.getFormat(event.target.files[0].name))
      await this.postSafe(`/structures/${this.id}/3Dobjects`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      await this.reload()
    },
    async delete (url) {
      if (!confirm('Opravdu chcete odstranit tento objekt?')) {
        return
      }

      await api.delete(url)
      await this.reload()
    },
    async reload () {
      this.structure = (await StructureService.getById(this.id)).data
      const temp = (await StructureService.get3dObjects(this.id)).data
      this.objects = temp.filter((obj) => obj.structureId === this.id)
      console.log(this.objects)

      this.origObjects = JSON.parse(JSON.stringify(this.objects))
    },
    async save () {
      for (var i = 0; i < this.objects.length; i++) {
        var obj = this.objects[i]
        const url = `/3Dobjects/${obj.id}/${obj.version}`
        const origobj = (await api.get(url)).data

        await api.put(url, {
          name: origobj.name,
          transformation: JSON.stringify(obj.transformation)
        })
      }
      await this.reload()
    },
    async postSafe (url, data, config) {
      try {
        await api.post(url, data, config)
      } catch (e) {
        alert(e)
      }
    }
  }
}
</script>

<style scoped>
div.col-6.col-sm-2 {
  width: 100px;
}
</style>
